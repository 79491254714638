<template>
  <div class="user-info">
    <div class="mess">基础信息</div>
    <div class="flex w80c p_in_02" v-if="infoList" v-loading.fullscreen.lock="loading">
      <div class="img-box">
        <el-image
            :src="infoList.avatar?infoList.avatar:avatarGirl"
            fit="cover"
            alt="加载失败"
        ></el-image>
      </div>
      <div class="info">
        <div class="w50">姓名：{{ infoList.name }}</div>
        <div class="w50">手机号码：{{ infoList.phone }}</div>
        <div class="w50">性别：{{ infoList.sex == 1 ? '男' :(infoList.sex == 2 ? '女' : '')  }}</div>
        <div class="w50">学校：{{ infoList.schoolName }}</div>
        <div class="w50">角色：{{ infoList.roleName }}</div>
      </div>
    </div>
    <div v-else
         style="display: flex;justify-content: center;align-items: center;color: #999;font-size: 20px;margin-top: 20rem">
      暂无数据
    </div>
  </div>
</template>

<script>
import avatarGirl from '@/assets/img/avatar_01.gif'
import {myInformation_api} from '@/api/mine'
// import {saveTelecastChat_api} from "@/api/course/subscribe";
// import {saveTelecastChat_api} from "@/api/course/subscribe";

export default {
  name: "userIndex",
  data() {
    return {
      avatarGirl,
      userId: '',
      // 加载
      loading: false,
      infoList: {
        name: '',
        phone: '',
        sex: '',
        schoolName: '',
        gradeName: '',
        roleName: '',
        avatar: ''
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {

    async getInfo() {
      this.loading = true
      let res = await myInformation_api({}).then(res => {
        this.loading = false
        return res
      })
      this.infoList.name = res.data.name
      this.infoList.phone = res.data.phone
      this.infoList.sex = res.data.sex
      this.infoList.schoolName = res.data.deptName
      this.infoList.roleName = res.data.roleName
      this.infoList.avatar = res.data.avatar
      this.userId = res.data.id
    },

  }
}
</script>

<style scoped>
.user-info {
  width      : 100%;
  height     : 80%;
  box-shadow : 1px 1px 20px #eee;
  background : #fff;
}

.mess {
  font-size     : 2rem;
  width         : 100%;
  text-align    : left;
  padding       : 0.5em 1.6em;
  box-sizing    : border-box;
  border-bottom : 1px solid #eee;
  color         : #666
}

.flex {
  display         : flex;
  align-items     : center;
  justify-content : center
}

.w80c {
  width  : 80%;
  height : 320px;
  margin : 0 auto;
}

.p_in_02 {
  padding    : 2em;
  box-sizing : border-box;
}

.img-box {
  width           : 90px;
  height          : 90px;
  border-radius   : 50%;
  background      : #eee;
  display         : flex;
  align-items     : center;
  justify-content : center;
}

.el-image {
  margin-left   : 5px;
  width         : 80px;
  height        : 80px;
  border-radius : 50%;
}

.info {
  margin-left : 8em;
  width       : 100%;
  display     : flex;
  flex-wrap   : wrap;
}

.w50 {
  width      : 50%;
  padding    : 1em 0;
  box-sizing : border-box;
  font-size  : 1.6em
}

</style>
